import { useContext, useState, useEffect, useMemo } from 'react';
import { classNames } from '@utils/helpers';
import { getLocalize } from '@utils/locales';
import { appState } from '@utils/store';
import Toggle from '@components/toggle';
import Typography from '@components/typography';
import Input from '../../../../../components/input';
import { BetContext } from '../../../../../context/bet';
import { FreeBetContext } from '../../../../../context/free-bet';
import styles from './index.module.css';

const l = getLocalize('game.bet');

const CashOut = () => {
  const { minAutoCashout, maxAutoCashout, defaultAutoCashout } = appState.provider;

  const { betValue, autoCashOut, setAutoCashOut, disabled } = useContext(BetContext);
  const checked = !!autoCashOut;

  const { isFreeBet, suggestions } = useContext(FreeBetContext);

  const freeMinCashout = useMemo(
    () => isFreeBet && suggestions.find(({ betNominal }) => betNominal === betValue)?.minMultiplier,
    [betValue, isFreeBet, suggestions]
  );

  const [input, setInput] = useState(autoCashOut || defaultAutoCashout);

  const onChange = (checked) => setAutoCashOut(checked ? input : null);

  useEffect(() => {
    setInput(autoCashOut || minAutoCashout || defaultAutoCashout);
  }, [autoCashOut, defaultAutoCashout, minAutoCashout]);

  useEffect(() => {
    if (autoCashOut) setInput(autoCashOut);
  }, [autoCashOut]);

  const setMultiplier = (value) => {
    setInput(value);
    if (checked) setAutoCashOut(value);
  };

  const containerClasses = classNames([styles.container, disabled && styles.disabled]);

  return (
    <div className={containerClasses}>
      <span className={styles.label}>
        <Typography variant="regular" size="12">
          {l('autoCashOut')}
        </Typography>
      </span>
      <Toggle checked={checked} onChange={onChange} />
      <Input
        label={l('cashOut')}
        suffix="x"
        disabled={!checked}
        value={input}
        setValue={setMultiplier}
        min={freeMinCashout || minAutoCashout}
        max={maxAutoCashout}
      />
    </div>
  );
};

export default CashOut;
