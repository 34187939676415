import { useCallback, useContext, useLayoutEffect, useRef } from 'react';
import { clamp, classNames, validateBet } from '@utils/helpers';
import { getLocalize } from '@utils/locales';
import { appState } from '@utils/store';
import Icon from '@components/icon';
import Input from '../../components/input';
import { BetContext } from '../../context/bet';
import Suggestions from './parts/suggestions';
import styles from './index.module.css';

const l = getLocalize('game.bet');

const BetInput = () => {
  const { betValue, setBetValue, disabled } = useContext(BetContext);
  const { minBet, maxBet, currency, defaultBet } = appState.provider;
  const suggested = useRef();

  useLayoutEffect(() => {
    if (!betValue) setBetValue(defaultBet);
  }, [betValue, defaultBet, setBetValue]);

  const onStep = (sign) => {
    const newValue = Number(betValue) + sign * defaultBet;
    const validValue = validateBet({ value: newValue, min: minBet, max: maxBet });
    setBetValue(validValue);
  };

  const setSuggestion = (value) => {
    const clampValue = (current) => clamp(minBet, current, maxBet);

    if (value === suggested.current) setBetValue((prev) => clampValue(prev + value));
    else {
      setBetValue(clampValue(value));
      requestAnimationFrame(() => (suggested.current = value));
    }
  };

  const onInput = useCallback(
    (value) => {
      suggested.current = null;
      setBetValue(value);
    },
    [setBetValue]
  );

  const containerClasses = classNames([styles.container, disabled && styles.disabled]);

  return (
    <div className={containerClasses}>
      <div className={styles.inputContainer}>
        <span className={styles.icon} onClick={() => onStep(-1)}>
          <Icon icon="Minus" />
        </span>
        <Input label={l('bet')} suffix={currency} value={betValue} setValue={onInput} min={minBet} max={maxBet} />
        <span className={styles.icon} onClick={() => onStep(1)}>
          <Icon icon="Plus" />
        </span>
      </div>
      <Suggestions onClick={setSuggestion} />
    </div>
  );
};

export default BetInput;
