import { useMemo } from 'react';
import { appState } from '@utils/store';
import Avatar from '@components/avatar';
import Typography from '@components/typography';
import { randomAvatar, savedAvatar } from '@game/parts/menu/items/profile/helpers';
import LChip from '../../../../components/chip';
import LItem from '../../../../components/item';
import styles from './index.module.css';

const getAvatar = (playerId) => {
  const userId = appState.session.playerId;
  if (playerId === userId) return savedAvatar();
  return randomAvatar(playerId);
};

const Item = ({ playerId, username, betAmount, win, multiplier }) => {
  const avatar = useMemo(() => getAvatar(playerId), [playerId]);

  const avatarRender = <Avatar name={avatar} size="small" />;

  return (
    <LItem isSuccess={!!win}>
      <div className={styles.player}>
        {avatarRender}
        <Typography variant="regular" size="14">
          {username}
        </Typography>
      </div>

      <div className={styles.bet}>
        <Typography variant="regular" size="14">
          {betAmount.toFixed(2)}
        </Typography>

        {win && <LChip>{multiplier.toFixed(2)}x</LChip>}
      </div>

      <div className={styles.win}>
        <Typography variant="regular" size="14">
          {win && win.toFixed(2)}
        </Typography>
      </div>
    </LItem>
  );
};

export default Item;
